import { render, staticRenderFns } from "./SearchSessionItem.vue?vue&type=template&id=407b5848&scoped=true"
import script from "./SearchSessionItem.vue?vue&type=script&lang=js"
export * from "./SearchSessionItem.vue?vue&type=script&lang=js"
import style0 from "./SearchSessionItem.vue?vue&type=style&index=0&id=407b5848&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "407b5848",
  null
  
)

export default component.exports